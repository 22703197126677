import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import bookmarksApi from '../api/bookmarksApi';

export const addBookmarkRequest = createAsyncThunk(
    'bookmarks/addBookmarkRequest',
    async (bookmark, _thunkAPI) => {
        try {
            const response = await bookmarksApi.postBookmark(bookmark);
            return await response.json();
        } catch (err) {
            console.error(err);
            throw err;
        }
    }
);

export const getAllBookmarksRequest = createAsyncThunk(
    'bookmarks/getAllBookmarksRequest',
    async (_, _thunkAPI) => {
        try {
            const response = await bookmarksApi.getBookmarks();
            return response;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }
);

export const addBookmark = (bookmarkData) => async (dispatch) => {
    await dispatch(addBookmarkRequest(bookmarkData));
    dispatch(getAllBookmarksRequest());
};

const bookmarksAdapter = createEntityAdapter({
    selectId: ({ id }) => id,
});
// const { selectById } = bookmarksAdapter.getSelectors();

const bookmarksSlice = createSlice({
    name: 'bookmarks',
    initialState: bookmarksAdapter.getInitialState([]),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addBookmarkRequest.fulfilled, (state, action) => {
            bookmarksAdapter.addOne(state, action.payload);
        });
        builder.addCase(getAllBookmarksRequest.fulfilled, (state, action) => {
            bookmarksAdapter.setAll(state, action.payload);
        });
    },
});

export const { selectById: selectBookmarkById, selectAll: selectAllBookmarks } =
    bookmarksAdapter.getSelectors((globalState) => globalState.bookmarks);

// export const {} = bookmarksSlice.actions;
export default bookmarksSlice.reducer;
