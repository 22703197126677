import React, { useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import ReactDOMClient from 'react-dom/client';

import './Map.css';

import CustomInfoWindow from './CustomInfoWindow';

import store from '../../store';

import {
    getAllBookmarksRequest,
    selectAllBookmarks,
} from '../../reducers/bookmarksSlice';

const DEFAULT_MAP_POSITION = { lat: 43.7181228, lng: -79.5428675 };

function Map() {
    const dispatch = useDispatch();

    const [currentMap, setCurrentMap] = useState(null);
    const [geolocation, setGeolocation] = useState(null);
    const [isGeolocalizing, setIsGeolocalizing] = useState(true);

    const bookmarks = useSelector(selectAllBookmarks);

    let currentMarker = null;
    let currentListener = null;

    const geolocalizeUser = () => {
        if ('geolocation' in navigator) {
            // Prompt user for permission to access their location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setGeolocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error('Error getting user location:', error);
                    setIsGeolocalizing(false);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            setIsGeolocalizing(false);
        }
    };

    const initMap = async () => {
        const { Map } = await google.maps.importLibrary('maps');

        const newMap = new Map(document.getElementsByClassName('Map')[0], {
            zoom: 15,
            center: DEFAULT_MAP_POSITION,
            mapId: 'FOODIE_MAP_DEFAULT',
            mapTypeControl: false,
            streetViewControl: false,
        });

        setCurrentMap(newMap);
    };

    useEffect(() => {
        geolocalizeUser();
        initMap();
    }, []);

    const isFoodiePlace = (placeTypes) => {
        const foodiePlaceTypes = [];
        placeTypes.forEach((placeType) => {
            if (['restaurant', 'food', 'bar', 'cafe'].includes(placeType)) {
                foodiePlaceTypes.push(placeType);
            }
        });
        return foodiePlaceTypes.length > 0;
    };

    const clearMarker = () => {
        currentMarker.setMap(null);
        currentMarker = null;
    };

    const displayPlace = async (currentMap, event) => {
        const request = {
            placeId: event.placeId,
            fields: [
                'name',
                'formatted_address',
                'place_id',
                'geometry',
                'business_status',
                'type',
                'url',
                'price_level',
                'rating',
                'user_ratings_total',
            ],
        };
        const service = new google.maps.places.PlacesService(currentMap);

        service.getDetails(request, async (place, status) => {
            const isLocationFoodiePlace = isFoodiePlace(place.types);

            // Default Google Maps API behavior if place doesn't respect criteria
            if (
                status === google.maps.places.PlacesServiceStatus.OK &&
                place?.geometry?.location
            ) {
                const { InfoWindow } = await google.maps.importLibrary('maps');
                const { AdvancedMarkerElement } =
                    await google.maps.importLibrary('marker');

                if (currentMarker) {
                    clearMarker();
                }
                currentMarker = new AdvancedMarkerElement({
                    position: place.geometry.location,
                    map: currentMap,
                });

                const customInfoWindowDiv = document.createElement('div');
                ReactDOMClient.createRoot(customInfoWindowDiv).render(
                    <Provider store={store}>
                        <CustomInfoWindow
                            placeDetails={place}
                            isPotentialNewBookmark={isLocationFoodiePlace}
                        />
                    </Provider>
                );
                const infoWindow = new InfoWindow({
                    content: customInfoWindowDiv,
                });

                infoWindow.open({
                    anchor: currentMarker,
                    map: currentMap,
                });

                infoWindow.addListener('closeclick', () => {
                    clearMarker();
                });
            }
        });
    };

    useEffect(() => {
        if (currentListener) {
            google.maps.event.removeListener(currentListener);
        }
        currentListener = currentMap?.addListener('click', (event) => {
            if (event.placeId) {
                event.stop();
                displayPlace(currentMap, event);
            }
        });
        dispatch(getAllBookmarksRequest());
    }, [currentMap]);

    useEffect(() => {
        currentMap?.setCenter(geolocation);
    }, [currentMap, isGeolocalizing]);

    const initBookmarkMarkers = async () => {
        const { AdvancedMarkerElement } = await google.maps.importLibrary(
            'marker'
        );

        bookmarks.forEach((bookmark) => {
            new AdvancedMarkerElement({
                position: { lat: bookmark.lat, lng: bookmark.lng },
                map: currentMap,
            });
        });
    };

    useEffect(() => {
        if (bookmarks.length > 0) {
            initBookmarkMarkers();
        }
    }, [bookmarks]);

    return <div className="Map" />;
}

export default Map;
